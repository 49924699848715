import * as React from "react"

// styles
//const center = {marginLeft: "auto", marginRight: "auto"}
const center = {textAlign: "center"}

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Catalytic.cc: Konst och teknik</title>
      <div style={center}>
        <h1>Catalytic.cc</h1>
        <p>Konst och teknik</p>
        <div>
          <a href="mailto:info@catalytic.cc">info@catalytic.cc</a>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
